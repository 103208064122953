// @import "variable.scss";

/* For use in src/lib/core/theming/_palette.scss */
$md-mobilse01: (
  50 : #f3f6f6,
  100 : #e1e9e8,
  200 : #cddad9,
  300 : #b9cbca,
  400 : #aabfbe,
  500 : #9bb4b3,
  600 : #93adac,
  700 : #89a4a3,
  800 : #7f9c9a,
  900 : #6d8c8b,
  A100 : #fff,
  A200 : #d6fefc,
  A400 : #a2fff9,
  A700 : #89fff8,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$md-mobilse02: (
  50 : #e8f0f8,
  100 : #c5daee,
  200 : #9ec2e3,
  300 : #77aad7,
  400 : #5a97cf,
  500 : #3d85c6,
  600 : #377dc0,
  700 : #2f72b9,
  800 : #2768b1,
  900 : #1a55a4,
  A100 : #dae9ff,
  A200 : #a7caff,
  A400 : #74abff,
  A700 : #5b9cff,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$md-mobilse03: (
  50 : #fef9f9,
  100 : #fcf0f0,
  200 : #fae6e6,
  300 : #f7dbdb,
  400 : #f6d4d4,
  500 : #f4cccc,
  600 : #f3c7c7,
  700 : #f1c0c0,
  800 : #efb9b9,
  900 : #ecadad,
  A100 : #fff,
  A200 : #fff,
  A400 : #fff,
  A700 : #fff,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$md-mobilse04: (
  50 : #fffaed,
  100 : #fff4d1,
  200 : #ffecb3,
  300 : #ffe494,
  400 : #ffdf7d,
  500 : #ffd966,
  600 : #ffd55e,
  700 : #ffcf53,
  800 : #ffca49,
  900 : #ffc038,
  A100 : #fff,
  A200 : #fff,
  A400 : #fff5e1,
  A700 : #ffedc8,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$md-dnt: (
  50 : #f8e8e6,
  100 : #eec5c0,
  200 : #e29f96,
  300 : #d6786c,
  400 : #ce5b4d,
  500 : #c53e2d,
  600 : #bf3828,
  700 : #b83022,
  800 : #b0281c,
  900 : #a31b11,
  A100 : #ffd6d4,
  A200 : #ffa6a1,
  A400 : #ff766e,
  A700 : #ff5e54,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);
