.d-flex {
  display: flex;
}

.justify-content-center {
  @extend .d-flex;

  justify-content: center !important;
}

.justify-content-end {
  @extend .d-flex;

  justify-content: flex-end !important;
}

.justify-content-space-between {
  @extend .d-flex;

  justify-content: space-between !important;
}

.align-items-center {
  @extend .d-flex;

  align-items: center !important;
}

.align-items-end {
  @extend .d-flex;

  align-items: flex-end !important;
}

.flex-column {
  @extend .d-flex;

  flex-direction: column !important;
  overflow: hidden;
}

.no-shrink {
  flex-shrink: 0;
}

.flex-full-width {
  flex: 1;
}
