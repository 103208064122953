@import '@angular/material/theming';

$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

// Dark Theme

/* Theme Colors */

// $topbar: #2196f3;
// $sidebar: #fff;
// $sidebar-white: #e5edef;
// $sidebar-alt: #f2f6f8;
// $bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
// $sidebar-text: #607d8b;
// $sidebar-icons: #99abb4;

$font-16: 1rem;

$themecolor: #1e88e5;

/* bootstrap Color */
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: var(--theme-primary-400);
$primary-dark: var(--theme-primary-700);
$primary-darker: var(--theme-primary-800);
$primary-contrast: var(--theme-primary-contrast-700);
$primary-light: var(--theme-primary-200);
$info: #1e88e5;
$inverse: #e3e3e3;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/* Light colors */
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/* Normal Color */
$white: #fff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/* Extra Variable */
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 0.012em;
$form-brd: #d9d9d9;

$page-wrapper-boxed-width: 3.61em;

// colors

$green-100: #f1f4f1;
$green-200: #e3eae3;
$green-300: #c7d5c6;
$green-400: #adc1ab;
$green-500: #91ab8f;
$green-600: #769773;

$blue-100: #edeff3;
$blue-200: #dce0e8;
$blue-300: #b8c2d1;
$blue-400: #95a3ba;
$blue-500: #7284a3;
$blue-600: #4f668c;

$black-100: #eaeaeb;
$black-200: #d6d6d7;
$black-300: #adadb0;
$black-400: #848588;
$black-500: #5b5c61;
$black-600: #33343a;

$yellow-100: #fbf6e7;
$yellow-200: #f7ecd0;
$yellow-300: #f3e3b9;
$yellow-400: #efd9a2;
$yellow-500: #ebd08b;
$yellow-600: #d0b268;

// size variables
$top-menu-height-offset: 4em;
$page-container-margin: 2em;
$accordion-title-width: 20vw;
