@import "styles/variable.scss";
@import "styles/flex.scss";
@import "styles/sidebar.scss";
@import "styles/spinner.scss";

* {
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: $bodyfont;
  margin: 0;
  overflow-x: hidden;
  color: $bodytext;
  background: #fff;
  font-weight: 400;
  font-size: 1rem;
}

html {
  position: relative;
  min-height: 100%;
}

.h-100 {
  height: 100%;
}

a:hover,
a:focus,
a {
  text-decoration: underline;
}

a.link {
  color: $headingtext;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: $radius;
}

html body .mdi::before,
html body .mdi-set {
  line-height: initial;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: $danger;
  background-color: $light-danger;
  border-radius: 0.25rem;
}

.shadow-none {
  box-shadow: none !important;
}

.ml-auto {
  margin-left: auto;
}

.m-auto {
  margin: auto;
}

.mr-auto {
  margin-right: auto;
}

.rounded {
  border-radius: $radius;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingfont;
  font-weight: 600;
}

h1 {
  line-height: 4em;
  font-size: 2.5rem;
}

h2 {
  line-height: 3rem;
  font-size: 2rem;
}

h3 {
  line-height: 2.413rem;
  font-size: 1.313rem;
}

h4 {
  line-height: 2.375rem;
  font-size: 1.125rem;
}

h5 {
  line-height: 2.125rem;
  font-size: 1rem;
  font-weight: 400;
}

h6 {
  line-height: 2rem;
  font-size: 0.875rem;
  font-weight: 400;
}

html,
body {
  .display-5 {
    font-size: 3rem;
  }

  .display-6 {
    font-size: 0.1em;
  }
}

.box {
  border-radius: $radius;
  padding: 0.625em;
}

html body {
  .dl {
    display: inline-block;
  }

  .db {
    display: block;
  }

  .op-5 {
    opacity: 0.5;
  }

  .op-3 {
    opacity: 0.3;
  }
}

.no-wrap {
  td,
  th {
    white-space: nowrap;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.oh {
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/*******************
Blockquote
*******************/

html body blockquote {
  border: 1px solid $border;
  border-left: 5px solid $themecolor;
  padding: 0.938em;
  margin: 1.25em 0;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0; /* stylelint-disable-line */
}

/*******************
Paddings
*******************/
html body {
  .p-0 {
    padding: 0;
  }

  .p-12 {
    padding: 0.75em;
  }

  .p-10 {
    padding: 0.625em;
  }

  .p-15 {
    padding: 0.938em;
  }

  .p-20 {
    padding: 1.25em;
  }

  .p-30 {
    padding: 0.083em;
  }

  .p-l-0 {
    padding-left: 0;
  }

  .p-l-5 {
    padding-left: 0.313em;
  }

  .p-r-5 {
    padding-right: 0.313em;
  }

  .p-l-10 {
    padding-left: 0.625em;
  }

  .p-r-15 {
    padding-right: 0.938em;
  }

  .p-l-20 {
    padding-left: 1.25em;
  }

  .p-l-25 {
    padding-left: 1.563em;
  }

  .p-r-0 {
    padding-right: 0;
  }

  .p-r-10 {
    padding-right: 0.625em;
  }

  .p-r-20 {
    padding-right: 1.25em;
  }

  .p-r-30 {
    padding-right: 1.875em;
  }

  .p-r-40 {
    padding-right: 2.5em;
  }

  .p-t-0 {
    padding-top: 0;
  }

  .p-t-10 {
    padding-top: 0.625em;
  }

  .p-t-15 {
    padding-top: 0.938em;
  }

  .p-t-20 {
    padding-top: 1.25em;
  }

  .p-t-30 {
    padding-top: 1.875em;
  }

  .p-b-0 {
    padding-bottom: 0;
  }

  .p-b-5 {
    padding-bottom: 0.313em;
  }

  .p-b-10 {
    padding-bottom: 0.625em;
  }

  .p-b-15 {
    padding-bottom: 1em;
  }

  .p-b-20 {
    padding-bottom: 1.25em;
  }

  .p-b-30 {
    padding-bottom: 1.875em;
  }

  .p-b-40 {
    padding-bottom: 2.5em;
  }

  .p-l-30 {
    padding-left: 1.875em;
  }

  .p-r-30 {
    padding-right: 1.875em;
  }
}

.text-dark-black {
  color: rgba(0, 0, 0, 0.87);
}

/*******************
Margin
*******************/
html body {
  .m-0 {
    margin: 0;
  }

  .mr-0 {
    margin-right: 0;
  }

  .ml-0 {
    margin-left: 0;
  }

  .m-15 {
    margin: 0.938em;
  }

  .m-l-5 {
    margin-left: 0.313em;
  }

  .m-l-10 {
    margin-left: 0.625em;
  }

  .m-l-15 {
    margin-left: 0.938em;
  }

  .m-l-20 {
    margin-left: 1.25em;
  }

  .m-l-30 {
    margin-left: 1.875em;
  }

  .m-l-40 {
    margin-left: 2.5em;
  }

  .m-r-5 {
    margin-right: 0.313em;
  }

  .m-r-10 {
    margin-right: 0.625em;
  }

  .m-r-15 {
    margin-right: 0.938em;
  }

  .m-r-20 {
    margin-right: 1.875em;
  }

  .m-r-30 {
    margin-right: 1.875em;
  }

  .m-r-40 {
    margin-right: 2.5em;
  }

  .m-t-0 {
    margin-top: 0;
  }

  .m-t-5 {
    margin-top: 0.313em;
  }

  .m-t-10 {
    margin-top: 0.625em;
  }

  .m-t-15 {
    margin-top: 0.938em;
  }

  .m-t-20 {
    margin-top: 1.875em;
  }

  .m-t-30 {
    margin-top: 1.875em;
  }

  .m-t-40 {
    margin-top: 2.5em;
  }

  .m-b-0 {
    margin-bottom: 0;
  }

  .m-b-5 {
    margin-bottom: 0.313em;
  }

  .m-b-10 {
    margin-bottom: 0.625em;
  }

  .m-b-15 {
    margin-bottom: 0.938em;
  }

  .m-b-20 {
    margin-bottom: 1.875em;
  }

  .m-b-40 {
    margin-bottom: 2.5em;
  }
}

/*******************
vertical alignment
*******************/
html body {
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }
}

/*******************
Opacity
*******************/
.op-5 {
  opacity: 0.5;
}

/*******************
font weight
*******************/
html body {
  .font-bold {
    font-weight: 700;
  }

  .font-normal {
    font-weight: normal;
  }

  .font-light {
    font-weight: 300;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-16 {
    font-size: 1em;
  }

  .font-14 {
    font-size: 0.875rem;
  }

  .font-20 {
    font-size: 1.5rem;
  }
}

/*******************
Border
*******************/
html body {
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border;
  }
}

/*******************
Thumb size
*******************/

.thumb-sm {
  height: 2em;
  width: 2em;
}

.thumb-md {
  height: 3em;
  width: 3em;
}

.thumb-lg {
  height: 5.5em;
  width: 5.5em;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: $radius;
}

/*******************
Text Colors
*******************/

.text-primary {
  color: $primary !important;
}

.text-primary-dark {
  color: $primary-dark !important;
}

.text-primary-contrast {
  color: $primary-contrast !important;
}

.text-primary-light {
  color: $primary-light !important;
}

.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-inverse {
  color: $inverse !important;
}

.otp-input {
  width: 2.5rem !important;
  height: 4rem !important;
  background-color: #c5b9b9;
}

html body {
  .text-blue {
    color: $blue;
  }

  .text-purple {
    color: $purple;
  }

  .text-primary {
    color: $primary;
  }

  .text-megna {
    color: $megna;
  }

  .text-dark {
    color: $bodytext;
  }

  .text-themecolor {
    color: $themecolor;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }
}

/*******************
Background Colors
*******************/

.bg-primary {
  background-color: $primary !important;
}

.bg-primary-dark {
  background-color: $primary-dark !important;
}

.bg-primary-light {
  background-color: $primary-light !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-transparant {
  background-color: transparent !important;
}

html body {
  .bg-megna {
    background-color: $megna;
  }

  .bg-theme {
    background-color: $themecolor;
  }

  .bg-inverse {
    background-color: $inverse;
  }

  .bg-purple {
    background-color: $purple !important;
  }

  .bg-light-primary {
    background-color: $light-primary;
  }

  .bg-light-success {
    background-color: $light-success;
  }

  .bg-light-info {
    background-color: $light-info;
  }

  .bg-light-extra {
    background-color: $extra-light;
  }

  .bg-light-warning {
    background-color: $light-warning;
  }

  .bg-light-danger {
    background-color: $light-danger;
  }

  .bg-light-inverse {
    background-color: $light-inverse;
  }

  .bg-light {
    background-color: $light;
  }

  .bg-white {
    background-color: $white;
  }
}

.img-circle {
  border-radius: 100%;
}

.no-shadow {
  box-shadow: none;
}

hr {
  border: 0;
  height: 1px; /* stylelint-disable-line */
  background: $border;
}

/*******************
Rounds
*******************/
.round {
  line-height: 3em;
  color: $white;
  width: 2.813em;
  height: 2.813em;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: $info;

  img {
    border-radius: 100%;
  }
}

.round-lg {
  line-height: 4.1em;
  width: 3.75em;
  height: 3.75em;
  font-size: 1.875rem;
}

.round.round-info {
  background: $info;
}

.round.round-warning {
  background: $warning;
}

.round.round-danger {
  background: $danger;
}

.round.round-success {
  background: $success;
}

.round.round-primary {
  background: $primary;
}

/*******************
Labels
*******************/
.input-label {
  font-size: 1.2rem;
  color: #808080;
}

.label {
  padding: 0.1875em 0.625em;
  line-height: 0.8125em;
  color: $white;
  font-weight: 400;
  border-radius: $radius;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px; /* stylelint-disable-line */
}

.label-custom {
  background-color: $megna;
}

.label-success {
  background-color: $success;
}

.label-info {
  background-color: $info;
}

.label-warning {
  background-color: $warning;
}

.label-danger {
  background-color: $danger;
}

.label-megna {
  background-color: $megna;
}

.label-primary {
  background-color: $primary;
}

.label-purple {
  background-color: $purple;
}

.label-red {
  background-color: $red;
}

.label-inverse {
  background-color: $inverse;
}

.label-default {
  background-color: $light;
}

.label-white {
  background-color: $white;
}

.label-light-success {
  background-color: $light-success;
  color: $success;
}

.label-light-info {
  background-color: $light-info;
  color: $info;
}

.label-light-warning {
  background-color: $light-warning;
  color: $warning;
}

.label-light-danger {
  background-color: $light-danger;
  color: $danger;
}

.label-light-megna {
  background-color: $light-megna;
  color: $megna;
}

.label-light-primary {
  background-color: $light-primary;
  color: $primary;
}

.label-light-inverse {
  background-color: $light-inverse;
  color: $inverse;
}

/*******************
Notify
*******************/

.notify {
  position: relative;
  top: -1.25em;
  right: 0;

  .point {
    width: 0.375em;
    height: 0.375em;
    -webkit-border-radius: 30px; /* stylelint-disable-line */
    -moz-border-radius: 30px; /* stylelint-disable-line */
    border-radius: 30px; /* stylelint-disable-line */
    background-color: $danger;
    position: absolute;
    right: 0.375em;
    top: -0.75em;
  }
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-lg {
  line-height: 3em !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

/*******************
froms
*******************/
.form-control {
  border: 0;
  line-height: 1.5625em;
  padding: 0.625em;
  width: 100%;
  font-size: $font-16;

  &.input-error {
    border: 1px solid $danger !important;
  }
}

.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.25);
}

.mat-form-field {
  width: 100%;
}

/*******************
Mat card
*******************/
.mat-card {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05) !important;
  margin: 1em;
  padding: 0 !important;
  border-radius: $radius;

  &.text-white {
    small {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }

  .mat-card-subtitle {
    font-weight: 400;
  }

  .mat-card-content {
    font-size: 1rem;
    line-height: 1.4375em;
    padding: 1.5em;
  }

  .mat-card-image {
    width: 100%;
    margin: 0 0 0 0;
  }

  .mat-card-header {
    padding: 0.75em;
    border-radius: $radius $radius 0 0;

    .mat-card-title {
      margin-bottom: 0.125em;
      font-size: 1.25em;
    }

    .mat-card-subtitle,
    .mat-card-subtitle:not(:first-child) {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 0.875em;
      font-weight: 400;
    }

    &.text-white {
      .mat-card-title {
        color: $white;
      }

      small,
      .mat-card-subtitle {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }

  .mat-card-title {
    font-size: 1.25em;
    margin-bottom: 0.313em;
  }

  .mat-card-subtitle {
    margin-bottom: 1.25em;
  }

  .mat-card-actions {
    margin: 0 !important;
  }
}

/*******************
Grid
*******************/
.row {
  margin: 0 -0.625em;
}

/*******************
Table
*******************/
.table {
  width: 100%;
  text-align: left;
}

.table-rasponsive {
  overflow: auto;

  .mat-table {
    min-width: 17.5em;
  }

  .mat-header-row {
    .mat-header-cell {
      white-space: nowrap;
    }
  }

  .mat-cell {
    white-space: nowrap;
  }
}

/*******************
Sparkline chart tooltip
*******************/
.jqstooltip {
  width: auto !important;
  height: auto !important;
  background: $inverse;
  color: $white;
  padding: 0.3125em 0.9375em;
  border: 0;
  border-radius: 3.75em;
}

/*******************
list inline
*******************/
.list-inline {
  margin-left: 0;
  padding: 0;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 0.625em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.button-row button {
  margin-right: 0.125em;
  margin-left: 0.125em;
}

.mini-spacer {
  margin-top: 5.625em;
}

/*******************
List-style-none
******************/

ul.list-style-none {
  margin: 0;
  padding: 0;

  li {
    list-style: none;

    a {
      color: $themecolor;
      padding: 0.5em 0;
      display: block;
      text-decoration: none;

      &:hover {
        color: $themecolor;
      }
    }
  }
}

.align-self-center {
  align-self: center;
}

.float-right {
  float: right;
}

.list-unstyled {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: flex;
    align-items: flex-start;
    padding: 0.9375em 0;
  }

  .shrink-none {
    flex-shrink: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.page-breadcrumb .breadcrumb .breadcrumb-item a {
  display: initial;
}

.apexcharts-text {
  fill: $muted;
}

.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line {
  stroke: rgba(0, 0, 0, 0.1);
}

.todo-toggle {
  position: absolute !important;
  z-index: 9;
  left: -5px; /* stylelint-disable-line */
}

@media (min-width: 48em) {
  .d-md-flex {
    display: flex;
  }
}

.input-file-button {
  input::-webkit-file-upload-button {
    background: transparent;
    border: 0;
    color: white;
  }
}

.login-register {
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
  background-color: #e3e3e3;
  background-size: cover;
  background-repeat: no-repeat;

  mat-card {
    height: 30em;
    margin: 0 auto;
  }

  .login-register-box {
    max-width: 25em;
    min-width: 22.5rem;
    margin: 0 auto;
    vertical-align: middle;
  }

  @media (max-width: 375px) { /* stylelint-disable-line */
    .login-register-box {
      max-width: 19em;
      min-width: 18.5rem;
    }

    h2 {
      line-height: 2rem;
      font-size: 1.5rem;
    }

    .login-register mat-card {
      height: 28rem;
    }
  }

  mat-card {
    border-radius: 15px; /* stylelint-disable-line */
    border: 1px solid gray;

    .logo-light-icon {
      width: 25%;
      margin-bottom: 1rem;
    }
  }

  .support-text {
    margin-top: -2em;
    display: block;
    padding-bottom: 0.0416em;
  }

  .error-card h1 {
    font-size: 9rem;
    font-weight: 900;
    line-height: 1.5em;
    margin: 0;
    color: #000;
    text-shadow: 4px 5px 1px #b3bbbf; /* stylelint-disable-line */
  }

  .submit-btn {
    position: absolute;
    bottom: 1em;
    left: 0;
    right: 0;
    padding: 0 1em;

    button {
      padding: 1.4em 1em;
      font-size: 1.3rem;
      letter-spacing: 0.031em;
    }
  }
}

mat-spinner {
  margin: 0 auto;
}

.topbar {
  position: fixed;
  z-index: 2;
  width: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .sidenav-container {
    flex: 1 0 auto;
  }
}

.page-container {
  padding-top: 4em;
  background-color: #fff;
}

.pagination {
  margin-top: 1em;
}

// material input css
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field-focus-overlay {
  background-color: #fff !important;
}

.mdc-text-field,
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

// material dialog css
mat-dialog-container {
  width: 50vw !important;
  height: auto !important;
  max-height: 80vh !important;
  min-height: 75vh !important;

  .mdc-dialog__surface {
    border-radius: 1em !important;
  }

  mat-dialog-content {
    max-height: 100% !important;
  }

  @media screen and (max-width: 767px) { /* stylelint-disable-line */
    width: 90vw !important;
  }
}

.modal-btn {
  margin: 2em 0;
}

.mat-button-no-color {
  color: rgba(0, 0, 0, 0.54) !important;
}

mat-form-field {
  width: 100%;
  margin-bottom: 0.7em;
}

.mat-expansion-panel-header-title {
  font-family: "Poppins", sans-serif !important;
}

//material pagination
.mat-mdc-paginator .mat-mdc-select {
  padding: 0 0.375em !important;
}

.btn-action {
  margin: 0 1em !important;
}

.lang-section {
  .mat-mdc-menu-item .mdc-list-item__primary-text {
    display: flex !important;
    align-items: center !important;
  }
}

.mat-card-avatar {
  margin-right: 1em;
  width: 4em;
  height: 4em;
  object-fit: cover;
}

.mat-icon-action {
  display: flex;

  mat-icon {
    margin: 0 0.3em;
  }
}

mat-divider.divider {
  border-top-color: #cac4d0;
}

.user-profile {
  height: 100%;

  mat-card {
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    padding-bottom: 3em;
    border-radius: 0.75em;
    margin-bottom: 2em;
  }

  mat-card-content {
    padding: 0 !important;
  }
}

/*******************
 Mail page
*******************/
.email-box {
  border: 1px solid $border;
  height: calc(100vh - 10.2em);
}

.email-sidebar {
  .mat-drawer-inner-container {
    overflow: visible;
  }

  border-right: 1px solid $border;
  width: 16em;

  .d-flex {
    display: flex;
    padding: 0.0416em 0;
    cursor: pointer;
  }

  .selected {
    .d-flex {
      background: $light;
    }
  }

  .icon-avatar {
    width: 0.166em;
    text-align: center;
    flex-shrink: 0;
  }

  .mail-title {
    font-weight: 500;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    max-width: 10.313em;
    text-overflow: ellipsis;
  }

  .avatar {
    width: 0.111em;
    border-radius: 100%;
  }
}

.email-right-panel {
  padding: 0.083em;
  background: $light;

  .avatar-lg {
    width: 0.278em;
    border-radius: 100%;
  }

  .row {
    overflow: hidden;
  }

  .col-md-2 {
    width: 20%;
    padding: 0.027em;
    float: $lft;
  }
}

.align-items-center {
  align-items: center;
}

@media (max-width: 991px) { /* stylelint-disable-line */
  .detail-part.movetodetail {
    display: block;
    position: absolute;
    background: #fff;
    z-index: 9;
    width: 100%;
  }
}

@media (max-width: 426px){ /* stylelint-disable-line */
  .login-register {
    width: 100%;
    display: inline-block;
    padding: 30% 0.625em 0.625em;
  }
}

@media (max-height: 481px){ /* stylelint-disable-line */
  .login-register {
    padding: 0;
  }
}
