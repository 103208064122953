@import 'variable.scss';

$mini-sidebar-width: 5em;

/*******************
Main sidebar
******************/

.mat-sidenav {
  width: auto;
  padding-top: 0;
}

#snav {
  position: fixed;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  min-width: 16em;
  width: 18em;
  margin-top: 4.3em;

  .mat-drawer-inner-container {
    overflow: hidden;
  }

  mat-nav-list {
    padding: 5em 1em;
    height: calc(100vh - 4.4em);
    overflow-y: auto;
  }

  a {
    &.active {
      background-color: $primary-darker;
      color: $primary-contrast;
      border-radius: 4px;
    }

    .menu-action {
      display: flex;
      align-items: center;
    }
  }
}

.mat-drawer-container {
  background-color: unset !important;
  height: 100vh;
}
